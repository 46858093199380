import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import GatsbyImage from 'gatsby-image';
import { customPropTypes, useTransitionDelay } from '../../../util';
import { STAGGER } from '../../../util/motion-manager/constant';
import { sanitizer } from '../../../util';

import './TeamGridItem.scss';

function TeamGridItem({ data: { name, title, bio, photo }, index }) {
  const transitionDelay = useTransitionDelay(STAGGER * (index + 1));
  return (
    <div className="TeamGridItem" style={transitionDelay}>
      <div className="photo-container">
        <GatsbyImage className="photo" fluid={photo?.localFile.childImageSharp.fluid} alt={photo?.altText} />
      </div>
      <div className="bio-container">
        <span className="name">{name}</span>
        <span className="title">{title}</span>
        <div className="bio" dangerouslySetInnerHTML={{ __html: sanitizer(bio) }} />
      </div>
    </div>
  );
}

TeamGridItem.propTypes = checkExtraProps({
  data: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    bio: PropTypes.string,
    photo: customPropTypes.image,
  }).isRequired,
  index: PropTypes.number,
});

TeamGridItem.defaultProps = {};

export default memo(TeamGridItem);
